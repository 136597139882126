import { useStore } from "@/store/store" // Update this import
import { NavigationGuardWithThis, RouteLocationNormalized } from "vue-router"

export const authGuard: NavigationGuardWithThis<undefined> = (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: any
) => {
  const store = useStore()

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isLoggedIn) {
      next()
      return
    }
    next("/login")
  } else {
    next()
  }
}
