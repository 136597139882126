import { UserState } from "@/interfaces/UserState"
import { createStore } from "vuex"

export * from "./store"

const store = createStore({
  state: {
    isLoggedIn: false,
    userData: null,
  } as UserState,
  mutations: {
    setUser(state, userData) {
      state.isLoggedIn = true
      state.userData = userData
    },
    clearUser(state) {
      state.isLoggedIn = false
      state.userData = null
    },
  },
  actions: {},
  modules: {},
})

export default store
