
import { defineComponent, onMounted, ref } from "vue"
import HandleBikes from "@/components/HandleBikes.vue"
import SubscribeModal from "@/components/SubscribeModal.vue"
import dbService from "@/services/dbService"

export default defineComponent({
  name: "RecentBikesView",
  components: { HandleBikes, SubscribeModal },
  setup() {
    const bikes = ref([])
    const isLoading = ref(true)
    const showModal = ref(false)
    const message = ref("")
    const starterBikesQuantity = 8

    async function fetchData(quantity: number) {
      try {
        const data = await dbService.getAllItemsByQuantity(quantity)
        bikes.value = data
        isLoading.value = false
      } catch (error) {
        isLoading.value = false
        bikes.value = []
        console.error("Error fetching data:", error)
      }
    }

    async function onLoadMore(loadedBikesQuantity: number) {
      await fetchData(loadedBikesQuantity)
    }

    onMounted(() => fetchData(starterBikesQuantity))

    return {
      bikes,
      isLoading,
      onLoadMore,
      showModal,
      message,
    }
  },
})
