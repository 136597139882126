import { InjectionKey } from "vue"
import { Store, useStore as baseUseStore } from "vuex"
import { UserState } from "@/interfaces/UserState"

export const key: InjectionKey<Store<UserState>> = Symbol()

// Define your own `useStore` composition function
export function useStore() {
  return baseUseStore(key)
}
