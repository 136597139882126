
import { defineComponent } from "vue"

import RecentBikes from "./RecentBikes.vue"
// import AboutUs from "./AboutUs.vue"
import ContactUs from "./ContactUs.vue"

export default defineComponent({
  name: "HomeView",
  components: {
    RecentBikes,
    // AboutUs,
    ContactUs,
  },
})
