import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getStorage, ref } from "firebase/storage" // Import for Firebase Storage

const firebaseConfig = {
  apiKey: "AIzaSyDb2ytOSCzPkqmScrdfriawbvd7nHlsRUA",
  authDomain: "themotorbikedoctor-20dd6.firebaseapp.com",
  projectId: "themotorbikedoctor-20dd6",
  storageBucket: "themotorbikedoctor-20dd6.appspot.com",
  messagingSenderId: "943936990333",
  appId: "1:943936990333:web:d8b62c36a4f87e631d5514",
  measurementId: "G-4E2VH0DW3G",
}

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const storage = getStorage(app) // Initialize Firebase Storage
const storageRef = ref(storage) // Get the Firebase Storage reference

export { app, auth, storageRef } // Export the storageRef alongside app and auth
