
import { computed, defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import { useVuelidate } from "@vuelidate/core"
import { required, minLength, numeric, maxLength } from "@vuelidate/validators"
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage"
import { storageRef as storage } from "@/firebase/firebaseConfig"
import dbService from "@/services/dbService"

import ClipLoader from "vue-spinner/src/ClipLoader.vue"

import heic2any from "heic2any"

export default defineComponent({
  name: "AddView",
  components: { ClipLoader },
  setup() {
    const isLoading = ref(false)
    const isLoadingImage = ref(false)
    const colorLoading = "#DC2626"

    const submitted = ref(false)

    const title = ref(null)
    const year = ref(null)
    const description = ref(null)
    const price = ref(null)

    const files = ref<Array<{ file: File; preview: string }>>([])
    const fileInput = ref<HTMLInputElement | null>(null)
    const maxImages = 12
    const isMaxImagesReached = ref(false)

    const message = ref("")
    const showMessage = ref(false)

    const rules = {
      title: { required, minLength: minLength(2), maxLength: maxLength(50) },
      year: { required, numeric },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(500),
      },
      price: { required, numeric },
      files: { required },
    }

    const v = useVuelidate(rules, { title, year, description, price, files })

    const router = useRouter()
    const backToAdminPage = () => {
      router.push("/admin")
    }

    function dragOver(e: DragEvent) {
      e.preventDefault()
    }

    function dragOut(e: DragEvent) {
      e.preventDefault()
    }

    async function dragDrop(e: DragEvent) {
      e.preventDefault()
      const dataTransfer = e.dataTransfer
      if (dataTransfer) {
        const dataFiles = Array.from(dataTransfer.files)
        await addFiles(dataFiles)
      }
    }

    async function uploadFiles(e: Event) {
      const target = e.target as HTMLInputElement
      if (target.files) {
        const selectedFiles = Array.from(target.files)
        await addFiles(selectedFiles)
        target.value = ""
      }
    }

    async function addFiles(selectedFiles: File[]) {
      for (const file of selectedFiles) {
        if (files.value.length >= maxImages) {
          isMaxImagesReached.value = true
          break
        } else {
          isMaxImagesReached.value = false
        }

        if (file.type === "image/heic" || file.type === "image/heif") {
          await convertToJPEGAndAdd(file)
        } else {
          const preview = await createPreview(file)
          files.value.push({ file, preview })
        }
      }
    }

    async function convertToJPEGAndAdd(file: File) {
      isLoadingImage.value = true

      const blob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
      })

      if (!Array.isArray(blob)) {
        const jpegFile = new File(
          [blob],
          file.name.replace(/\.(heic|heif)/, ".jpeg"),
          {
            type: "image/jpeg",
            lastModified: Date.now(),
          }
        )

        const preview = await createPreview(jpegFile)
        files.value.push({ file: jpegFile, preview })
      }

      isLoadingImage.value = false
    }

    function createPreview(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => resolve(e.target?.result as string)
        reader.onerror = () => reject("Unable to read file")
      })
    }

    function browseFile() {
      fileInput.value?.click()
    }

    function deleteImage(index: number) {
      files.value.splice(index, 1)
      isMaxImagesReached.value = false
    }

    async function uploadImagesToStorage(itemId: any, files: { file: any }[]) {
      const uploadPromises = files.map(async ({ file }, index) => {
        const imageID = Date.now() + "-item-" + index
        const imageRef = storageRef(storage, `bikes/${itemId}/${imageID}`)

        // Convert the file to blob
        const blobResult = new Blob([file], { type: file.type })

        await uploadBytesResumable(imageRef, blobResult)
        const downloadURL = await getDownloadURL(imageRef)
        return downloadURL
      })

      const imageUrls = await Promise.all(uploadPromises)
      return imageUrls
    }

    async function addItem() {
      submitted.value = true
      v.value.$touch()

      if (hasAnyError.value) {
        return
      }

      try {
        isLoading.value = true

        const createdDocument = await dbService.createItem({
          title: title.value || null,
          year: year.value || null,
          description: description.value || null,
          price: price.value || null,
        })

        const itemId = createdDocument._id
        const imageUrls = await uploadImagesToStorage(itemId, files.value)

        const withImagesDocument = await dbService.updateItem(itemId, {
          images: imageUrls,
        })

        if (withImagesDocument._id) {
          message.value = "Bike added successfully"
          showMessage.value = true
          setTimeout(() => {
            showMessage.value = false
            isLoading.value = false
            backToAdminPage()
          }, 3000)
        }

        // send email with the new product to the subscribers
        await dbService.sendEmailSubscribers(itemId)
      } catch (error) {
        if (error instanceof Error) {
          console.error("Error:", error.message)
        } else {
          console.error("An error occurred while adding the item")
        }
      }
    }

    const hasAnyError = computed(() => {
      return (
        v.value.title.$error ||
        v.value.year.$error ||
        v.value.description.$error ||
        v.value.price.$error ||
        v.value.files.$error
      )
    })

    return {
      isLoading,
      isLoadingImage,
      colorLoading,
      submitted,
      backToAdminPage,
      files,
      fileInput,
      dragOver,
      dragOut,
      dragDrop,
      browseFile,
      uploadFiles,
      deleteImage,
      maxImages,
      isMaxImagesReached,
      title,
      year,
      description,
      price,
      addItem,
      v: v.value,
      showMessage,
      message,
    }
  },
})
