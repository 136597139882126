import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex-grow" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RecentBikes = _resolveComponent("RecentBikes")!
  const _component_ContactUs = _resolveComponent("ContactUs")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(_component_RecentBikes),
    _createVNode(_component_ContactUs)
  ]))
}