
import { useStore } from "@/store/store"
import dbService from "@/services/dbService"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { computed, defineComponent, ref } from "vue"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import { useRouter } from "vue-router"

export default defineComponent({
  name: "LoginView",
  components: { ClipLoader },
  setup() {
    const store = useStore()
    const router = useRouter()

    const isLoading = ref(false)
    const colorLoading = "#DC2626"

    const submitted = ref(false)

    const username = ref("")
    const password = ref("")
    const loginError = ref("")

    const rules = {
      username: { required },
      password: { required },
    }

    const v = useVuelidate(rules, { username, password })

    async function login() {
      submitted.value = true
      v.value.$touch()

      if (hasAnyError.value) {
        return
      }

      isLoading.value = true
      loginError.value = ""

      const response = await dbService.login(username.value, password.value)

      if (response.isLoggedIn) {
        store.commit("setUser", {
          _id: response.user._id,
          username: response.user.username,
        })

        router.push("/admin")
      } else {
        isLoading.value = false
        loginError.value = response.error
      }
    }

    const hasAnyError = computed(() => {
      return v.value.username.$error || v.value.password.$error
    })

    return {
      isLoading,
      colorLoading,
      submitted,
      username,
      password,
      loginError,
      v: v.value,
      login,
    }
  },
})
