import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-200 py-10 px-12" }
const _hoisted_2 = { class: "text-right font-bold py-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HandleBikes = _resolveComponent("HandleBikes")!
  const _component_SubscribeModal = _resolveComponent("SubscribeModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_HandleBikes, {
        bikes: _ctx.bikes,
        isLoading: _ctx.isLoading,
        onLoadMore: _ctx.onLoadMore
      }, null, 8, ["bikes", "isLoading", "onLoadMore"]),
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_2, [
          _createTextVNode(" Subscribe "),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
            class: "text-red-600 font-bold"
          }, " HERE "),
          _createTextVNode(" and receive notifications of latest bikes by email! ")
        ])
      ])
    ]),
    _createVNode(_component_SubscribeModal, {
      show: _ctx.showModal,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModal = false))
    }, null, 8, ["show"]),
    _createElementVNode("p", null, _toDisplayString(_ctx.message), 1)
  ], 64))
}