import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import Home from "../views/Home.vue"
import Bikes from "../views/Bikes.vue"
import Bike from "../views/Bike.vue"
import Admin from "../views/Admin.vue"
import Add from "../views/Add.vue"
import Edit from "../views/Edit.vue"
import Login from "../views/Login.vue"
import Unsubscribe from "../views/Unsubscribe.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/bikes",
    name: "bikes",
    component: Bikes,
  },
  {
    path: "/",
    name: "about",
    component: Home,
  },
  {
    path: "/",
    name: "contact",
    component: Home,
  },
  {
    path: "/bike/:id",
    name: "bike",
    component: Bike,
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
  },
  {
    path: "/add",
    name: "add",
    component: Add,
  },
  {
    path: "/edit/:id",
    name: "edit",
    component: Edit,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/unsubscribe",
    name: "unsubscribe",
    component: Unsubscribe,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "header-active",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      }
    }
  },
})

export default router
