import axios, { AxiosError } from "axios"

const dev = "http://localhost:3000"
const prod = "https://themotorbikedoctor-app.herokuapp.com"

//const API_URL = dev
const API_URL = prod

export default {
  async getAllItems() {
    try {
      const response = await axios.get(`${API_URL}/items`)
      return response.data
    } catch (error) {
      throw new Error(`Error fetching items: ${error}`)
    }
  },

  async getAllItemsByQuantity(quantity: number) {
    try {
      const response = await axios.get(`${API_URL}/items/quantity/${quantity}`)
      return response.data
    } catch (error) {
      throw new Error(`Error fetching items: ${error}`)
    }
  },

  async getItem(id: string) {
    try {
      const response = await axios.get(`${API_URL}/items/${id}`)
      return response.data
    } catch (error) {
      throw new Error(`Error fetching item: ${error}`)
    }
  },

  async createItem(document: object) {
    try {
      const response = await axios.post(`${API_URL}/items`, document)
      return response.data
    } catch (error) {
      throw new Error(`Error creating item: ${error}`)
    }
  },

  async updateItem(id: string, document: object) {
    try {
      const response = await axios.put(`${API_URL}/items/${id}`, document)
      return response.data
    } catch (error) {
      throw new Error(`Error updating item: ${error}`)
    }
  },

  async deleteItem(id: string) {
    try {
      const response = await axios.delete(`${API_URL}/items/${id}`)
      return response.data
    } catch (error) {
      throw new Error(`Error deleting item: ${error}`)
    }
  },

  async searchBikes(
    searchText: string,
    minPrice: number,
    maxPrice: number,
    quantity: number
  ) {
    try {
      const response = await axios.get(
        `${API_URL}/search?q=${searchText}&minPrice=${minPrice}&maxPrice=${maxPrice}&quantity=${quantity}`
      )
      return response.data
    } catch (error) {
      throw new Error(`Error fetching items: ${error}`)
    }
  },

  async login(username: string, password: string) {
    try {
      const response = await axios.post(`${API_URL}/users/login`, {
        username,
        password,
      })
      return response.data
    } catch (error) {
      const err = error as AxiosError

      if (err.response) {
        return err?.response.data
      } else {
        return { error: "Server Error" }
      }
    }
  },

  async subscribe(email: string, name: string) {
    try {
      const response = await axios.post(`${API_URL}/subscribe`, { email, name })
      return response.data
    } catch (error) {
      const err = error as AxiosError

      if (err.response) {
        return err?.response.data
      } else {
        return { error: "Server Error" }
      }
    }
  },

  async unsubscribe(email: string) {
    try {
      const response = await axios.post(`${API_URL}/unsubscribe`, { email })
      return response.data
    } catch (error) {
      const err = error as AxiosError

      if (err.response) {
        return err?.response.data
      } else {
        return { error: "Server Error" }
      }
    }
  },

  async sendEmailSubscribers(id: string) {
    try {
      const response = await axios.post(`${API_URL}/sendEmail`, { id })
      return response.data
    } catch (error) {
      console.error(error)
    }
  },
}
