
import { defineComponent, ref } from "vue"
import dbService from "@/services/dbService"

export default defineComponent({
  name: "SubscribeModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["close"],
  setup(_, { emit }) {
    const email = ref("")
    const name = ref("")
    const isSubscribed = ref(false)
    const message = ref("")

    const subscribe = async () => {
      isSubscribed.value = false
      if (!email.value) {
        message.value = "Please inform your email!"
        return
      }

      if (!name.value) {
        message.value = "Please inform your name!"
        return
      }

      // Regular expression for basic email validation
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

      if (!email.value.match(emailRegex)) {
        message.value = "Please enter a valid email address!"
        return
      }

      const response = await dbService.subscribe(email.value, name.value)

      if (response.error) {
        message.value = response.error
        return
      }

      isSubscribed.value = true
      email.value = ""
      name.value = ""
      message.value = "Your email has been subscribed."
    }

    function closeModal() {
      email.value = ""
      name.value = ""
      message.value = ""
      isSubscribed.value = false
      emit("close")
    }

    return {
      email,
      name,
      isSubscribed,
      subscribe,
      message,
      closeModal,
    }
  },
})
