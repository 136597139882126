
import { defineComponent, onMounted, ref } from "vue"
import HandleBikes from "@/components/HandleBikes.vue"
import Slider from "@vueform/slider"
import dbService from "@/services/dbService"

export default defineComponent({
  name: "BikesView",
  components: { HandleBikes, Slider },
  setup() {
    const bikes = ref([])
    const isLoading = ref(false)

    const searchText = ref("")
    const minPrice = 500
    const maxPrice = 50000
    const price = ref([minPrice, maxPrice])
    const starterBikesQuantity = 8

    async function fetchData(quantity: number) {
      isLoading.value = true

      try {
        const data = await dbService.searchBikes(
          searchText.value,
          price.value[0],
          price.value[1],
          quantity
        )
        bikes.value = data
        isLoading.value = false
      } catch (error) {
        isLoading.value = false
        bikes.value = []
        console.error("Error fetching data:", error)
      }
    }

    async function onLoadMore(loadedBikesQuantity: number) {
      await fetchData(loadedBikesQuantity)
    }

    async function filterBikes() {
      await fetchData(starterBikesQuantity)
    }

    onMounted(() => filterBikes())

    return {
      bikes,
      searchText,
      price,
      minPrice,
      maxPrice,
      isLoading,
      onLoadMore,
      filterBikes,
    }
  },
})
