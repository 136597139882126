
import { computed, defineComponent, onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useVuelidate } from "@vuelidate/core"
import { required, minLength, numeric, maxLength } from "@vuelidate/validators"
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
  deleteObject,
  listAll,
} from "firebase/storage"
import { storageRef as storage } from "@/firebase/firebaseConfig"
import dbService from "@/services/dbService"

import ClipLoader from "vue-spinner/src/ClipLoader.vue"

import heic2any from "heic2any"

export default defineComponent({
  name: "EditView",
  components: { ClipLoader },
  setup() {
    const isLoading = ref(false)
    const isLoadingImage = ref(false)
    const colorLoading = "#DC2626"

    const submitted = ref(false)

    const bikeId = ref("")
    const title = ref(null)
    const year = ref(null)
    const description = ref(null)
    const price = ref(null)

    const files = ref<Array<{ file: File; preview: string }>>([])
    const storageFiles: any[] = []
    const fileInput = ref<HTMLInputElement | null>(null)
    const maxImages = 12
    const isMaxImagesReached = ref(false)

    const message = ref("")
    const showMessage = ref(false)

    const rules = {
      title: { required, minLength: minLength(2), maxLength: maxLength(50) },
      year: { required, numeric },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(500),
      },
      price: { required, numeric },
      files: { required },
    }

    const v = useVuelidate(rules, { title, year, description, price, files })

    const router = useRouter()
    const route = useRoute()

    const backToAdminPage = () => {
      router.push("/admin")
    }

    function dragOver(e: DragEvent) {
      e.preventDefault()
    }

    function dragOut(e: DragEvent) {
      e.preventDefault()
    }

    async function dragDrop(e: DragEvent) {
      e.preventDefault()
      const dataTransfer = e.dataTransfer
      if (dataTransfer) {
        const dataFiles = Array.from(dataTransfer.files)
        await addFiles(dataFiles)
      }
    }

    async function uploadFiles(e: Event) {
      const target = e.target as HTMLInputElement
      if (target.files) {
        const selectedFiles = Array.from(target.files)
        await addFiles(selectedFiles)
        target.value = ""
      }
    }

    async function addFiles(selectedFiles: File[]) {
      for (const file of selectedFiles) {
        if (files.value.length >= maxImages) {
          isMaxImagesReached.value = true
          break
        } else {
          isMaxImagesReached.value = false
        }

        if (file.type === "image/heic" || file.type === "image/heif") {
          await convertToJPEGAndAdd(file)
        } else {
          const preview = await createPreview(file)
          files.value.push({ file, preview })
        }
      }
    }

    async function convertToJPEGAndAdd(file: File) {
      isLoadingImage.value = true

      const blob = await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 1,
      })

      if (!Array.isArray(blob)) {
        const jpegFile = new File(
          [blob],
          file.name.replace(/\.(heic|heif)/, ".jpeg"),
          {
            type: "image/jpeg",
            lastModified: Date.now(),
          }
        )

        const preview = await createPreview(jpegFile)
        files.value.push({ file: jpegFile, preview })
      }

      isLoadingImage.value = false
    }

    function browseFile() {
      fileInput.value?.click()
    }

    function createPreview(file: File): Promise<string> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (e) => resolve(e.target?.result as string)
        reader.onerror = () => reject("Unable to read file")
      })
    }

    function deleteImage(index: number) {
      files.value.splice(index, 1)
      isMaxImagesReached.value = false
    }

    async function uploadImagesToStorage(itemId: any, files: { file: any }[]) {
      const uploadTasks = files.map(async ({ file }, index) => {
        const imageID = Date.now() + "-item-" + index
        const imageRef = storageRef(storage, `bikes/${itemId}/${imageID}/`)

        // Convert the file to blob
        const blobResult = new Blob([file], { type: file.type })

        const metadata = { contentType: blobResult.type }
        await uploadBytes(imageRef, blobResult, metadata)
      })

      await Promise.all(uploadTasks)
    }

    async function getAllImages(id: string) {
      const directoryRef = storageRef(storage, `bikes/${id}`)
      const listResult = await listAll(directoryRef)
      const imageRefs = listResult.items

      const downloadURLsPromises = imageRefs.map((imageRef) =>
        getDownloadURL(imageRef)
      )

      const downloadURLs = await Promise.all(downloadURLsPromises)

      return downloadURLs
    }

    async function deleteImagesStorage(files: any[]) {
      const deleteTasks = files.map(async (file: any) => {
        const path = getPathFromUrl(file)
        try {
          const storageFolderRef = storageRef(storage, path)
          return await deleteObject(storageFolderRef)
        } catch (error) {
          console.error("Error deleting folder contents:", error)
        }
      })

      await Promise.all(deleteTasks)
    }

    function getPathFromUrl(url: string) {
      const parsedUrl: URL = new URL(url)
      const startingPoint: number = parsedUrl.pathname.indexOf("bikes")

      return decodeURIComponent(
        parsedUrl.pathname.substring(startingPoint)
      ).replace(/%2F/g, "/")
    }

    async function updateItem() {
      submitted.value = true
      v.value.$touch()

      if (hasAnyError.value) {
        return
      }

      isLoading.value = true

      // Update the item with the specified id
      const itemData = {
        title: title.value || null,
        year: year.value || null,
        description: description.value || null,
        price: price.value || null,
      }

      try {
        await dbService.updateItem(bikeId.value, itemData)
      } catch (error) {
        console.error(
          "An error occurred while updating the bike details: ",
          error
        )
        isLoading.value = false
        return
      }

      // Check if there are newly added files
      const newFiles = files.value.filter((file) => file.file)

      // Check if any files were deleted
      const deletedFiles = storageFiles.filter((file) => {
        return !files.value.includes(file)
      })

      try {
        // Delete the old files that were removed
        await deleteImagesStorage(deletedFiles)

        // Upload new files, if any
        if (newFiles.length > 0) {
          await uploadImagesToStorage(bikeId.value, newFiles)
        }

        // Fetch references to all files in the folder
        const imageUrls = await getAllImages(bikeId.value)

        const withImagesDocument = await dbService.updateItem(bikeId.value, {
          images: imageUrls,
        })

        if (withImagesDocument._id) {
          message.value = "Bike has been updated"
          showMessage.value = true
          setTimeout(() => {
            showMessage.value = false
            isLoading.value = false
            backToAdminPage()
          }, 3000)
        }
      } catch (error) {
        console.error("An error occurred while updating images: ", error)
      }
    }

    const hasAnyError = computed(() => {
      return (
        v.value.title.$error ||
        v.value.year.$error ||
        v.value.description.$error ||
        v.value.price.$error ||
        v.value.files.$error
      )
    })

    async function fetchItem() {
      isLoading.value = true
      try {
        const bike = await dbService.getItem(String(route.params.id))

        bikeId.value = bike._id
        title.value = bike.title
        year.value = bike.year
        description.value = bike.description
        price.value = bike.price
        files.value = bike.images

        bike.images.forEach((image: any) => {
          storageFiles.push(image)
        })

        isLoading.value = false
      } catch (error) {
        console.error("Error fetching item:", error)
      }
    }

    onMounted(fetchItem)

    return {
      isLoading,
      isLoadingImage,
      colorLoading,
      submitted,
      backToAdminPage,
      files,
      fileInput,
      dragOver,
      dragOut,
      dragDrop,
      browseFile,
      uploadFiles,
      deleteImage,
      maxImages,
      isMaxImagesReached,
      bikeId,
      title,
      year,
      description,
      price,
      updateItem,
      v: v.value,
      showMessage,
      message,
    }
  },
})
