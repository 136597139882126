import { createApp } from "vue"
import App from "./App.vue"

import router from "./router"
import { authGuard } from "./router/guards"
import store, { key } from "@/store"

import "./assets/tailwind.css"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import {
  faLocationDot,
  faPhone,
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faEdit,
  faAdd,
  faXmark,
  faRightToBracket,
  faPersonWalkingArrowRight,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import VueLazyload from 'vue3-lazy';

library.add(
  faLocationDot,
  faPhone,
  faEnvelope,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faEdit,
  faAdd,
  faXmark,
  faWhatsapp,
  faRightToBracket,
  faPersonWalkingArrowRight
)

router.beforeEach(authGuard)

createApp(App)
  .use(store, key)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(VueLazyload, {})
  .mount("#app")
