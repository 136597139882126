
import { computed, defineComponent, ref } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "@/store/store"

export default defineComponent({
  name: "HeaderComponent",
  components: {},
  setup() {
    const title = ref("THE MOTORBIKE DOCTOR")

    const store = useStore()
    const router = useRouter()
    const isLoggedIn = computed(() => store.state.isLoggedIn)

    // function login() {
    //   router.push("/login")
    // }

    // function logout() {
    //   store.commit("clearUser")
    //   router.push("/")
    // }

    return { isLoggedIn, /*login, logout,*/ title }
  },
})
