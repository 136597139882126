
import { defineComponent, onMounted, ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import "vue3-carousel/dist/carousel.css"
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel"
import ContactMe from "@/components/ContactMe.vue"
import dbService from "@/services/dbService"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

export default defineComponent({
  name: "BikesView",
  components: { Carousel, Slide, Pagination, Navigation, ContactMe, ClipLoader },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const bike = ref({
      title: "",
      year: "",
      description: "",
      price: 0,
      images: [],
    })
    const zoomedIndex = ref(-1)
    const showModal = ref(false)
    const colorLoading = "#DC2626"

    async function fetchItem() {
      try {
        const data = await dbService.getItem(String(route.params.id))
        bike.value = data
      } catch (error) {
        console.error("Error fetching item:", error)
      }
    }

    const toggleZoom = function(index: number): void {
      showModal.value = !showModal.value
      zoomedIndex.value = zoomedIndex.value === index ? -1 : index
    }

    const isZoomed = function(index: number): boolean {
      return zoomedIndex.value === index;
    }

    const backToHomePage = () => {
      router.push(String(router.options.history.state.back))
    }

    const bikeTitle = computed(() => `${bike.value.year} ${bike.value.title}`)

    const formattedDescription = computed(() => {
      if (bike.value.description) {
        return bike.value.description.replace(/\n/g, "<br/>")
      }
      return ""
    })

    onMounted(fetchItem)

    return { bike, backToHomePage, bikeTitle, formattedDescription, toggleZoom, isZoomed, zoomedIndex, showModal, colorLoading }
  },
})
