
import { defineComponent, ref } from "vue"
import { LMap, LMarker, LTooltip, LTileLayer } from "@vue-leaflet/vue-leaflet"

export default defineComponent({
  name: "LocationView",
  components: { LMap, LMarker, LTooltip, LTileLayer },
  setup() {
    const zoom = ref(18)
    const latitude = ref(53.342795)
    const longitude = ref(-6.243999)
    const name = ref("The Motorbike Doctor")
    const address = ref("13A Brunswick Place, Dublin 2, D02 VK57")

    return { zoom, latitude, longitude, name, address }
  },
})
