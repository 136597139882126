
import { defineComponent, ref } from "vue"
import dbService from "@/services/dbService"
import { useRouter } from "vue-router"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

export default defineComponent({
  name: "UnsubscribeView",
  components: { ClipLoader },
  setup() {
    const email = ref("")
    const isUnsubscribed = ref(false)
    const message = ref("")

    const isLoading = ref(false)
    const colorLoading = "#DC2626"

    const unsubscribe = async () => {
      isUnsubscribed.value = false
      if (!email.value) {
        message.value = "Please inform your email!"
        return
      }

      // Regular expression for basic email validation
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/

      if (!email.value.match(emailRegex)) {
        message.value = "Please enter a valid email address!"
        return
      }

      isLoading.value = true
      const response = await dbService.unsubscribe(email.value)
      isLoading.value = false

      if (response.error) {
        message.value = response.error
        return
      }

      isUnsubscribed.value = true
      email.value = ""
      message.value = "Your email has been unsubscribed!"
    }

    const router = useRouter()
    const backToHomePage = () => {
      router.push("/")
    }

    return {
      email,
      isUnsubscribed,
      message,
      unsubscribe,
      backToHomePage,
      isLoading,
      colorLoading,
    }
  },
})
