import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "grid grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4" }
const _hoisted_3 = { class: "rounded-t h-72 w-full object-cover bg-gray-300" }
const _hoisted_4 = { class: "p-4" }
const _hoisted_5 = { class: "text-xl text-left mb-4 font-bold leading-relaxed text-gray-300" }
const _hoisted_6 = { class: "leading-5 text-sm text-gray-300" }
const _hoisted_7 = {
  key: 0,
  class: "text-center py-5 text-lg font-bold text-gray-700"
}
const _hoisted_8 = {
  key: 1,
  class: "font-bold py-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClipLoader = _resolveComponent("ClipLoader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_ClipLoader, {
        key: 0,
        color: _ctx.colorLoading
      }, null, 8, ["color"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bikes, (bike, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "my-8 rounded shadow-lg shadow-gray-600 bg-gray-700 duration-300 hover:-translate-y-1",
              key: index
            }, [
              _createVNode(_component_router_link, {
                _href: "link",
                class: "cursor-pointer",
                to: { path: `/bike/${bike._id}` }
              }, {
                default: _withCtx(() => [
                  _createElementVNode("figure", null, [
                    _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
                      [_directive_lazy, bike.images[0]]
                    ]),
                    _createElementVNode("figcaption", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, " €" + _toDisplayString(bike.price), 1),
                      _createElementVNode("small", _hoisted_6, _toDisplayString(`${bike.year} ${bike.title}`), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ]),
        (_ctx.bikes.length === 0 && !_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " No data found "))
          : _createCommentVNode("", true),
        (
        _ctx.bikes.length == _ctx.loadedBikesQuantity ||
        _ctx.bikes.length > _ctx.loadedBikesQuantity
      )
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadMoreBikes && _ctx.loadMoreBikes(...args))),
                class: "border-2 border-gray-700 hover:bg-gray-700 text-gray-700 hover:text-white font-bold py-2 px-4 rounded-full"
              }, " Show more ")
            ]))
          : _createCommentVNode("", true)
      ]))
}