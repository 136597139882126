
import { defineComponent, onMounted, computed, ref } from "vue"
import { useRouter } from "vue-router"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"
import dbService from "@/services/dbService"
import {
  deleteObject,
  listAll,
  StorageReference,
  ref as storageRef,
} from "firebase/storage"
import { storageRef as storage } from "@/firebase/firebaseConfig"
import { Bike } from "@/interfaces/Bike"
import store from "@/store"

export default defineComponent({
  name: "AdminView",
  components: { ClipLoader },
  setup() {
    const router = useRouter()

    const bikes = ref<Bike[]>([])

    const isLoading = ref(true)
    const colorLoading = "#0369A1"

    const searchText = ref("")
    const filteredBikes = ref<Bike[]>([])

    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const totalPages = ref(1)

    const showDeleteConfirmation = ref(false)
    const selectedBikeId = ref<string>("")
    const selectedBikeTitle = ref<string>("")

    const message = ref("")
    const showMessage = ref(false)

    const isLoggedIn = computed(() => store.state.isLoggedIn)

    async function fetchData() {
      try {
        const allItems = await dbService.getAllItems()
        bikes.value = allItems
        filteredBikes.value = allItems
        totalPages.value = Math.ceil(allItems.length / itemsPerPage.value)
        isLoading.value = false
      } catch (error) {
        bikes.value = []
        isLoading.value = false
        console.error("Error fetching data:", error)
      }
    }

    function nextPage() {
      if (currentPage.value < totalPages.value) {
        currentPage.value++
      }
    }

    function previousPage() {
      if (currentPage.value > 1) {
        currentPage.value--
      }
    }

    const paginatedBikes = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage.value
      const endIndex = startIndex + itemsPerPage.value
      return filteredBikes.value.slice(startIndex, endIndex)
    })

    function filterBikes() {
      filteredBikes.value = bikes.value.filter((bike) =>
        bike.title.toLowerCase().includes(searchText.value.toLowerCase())
      )
      currentPage.value = 1
      totalPages.value = Math.ceil(
        filteredBikes.value.length / itemsPerPage.value
      )
    }

    function toggleDeleteConfirmation(id: string) {
      selectedBikeId.value = id
      getTitlefromId(id)
      showDeleteConfirmation.value = !showDeleteConfirmation.value
    }

    function getTitlefromId(id: string) {
      const bike = bikes.value.find((bike) => bike._id === id)
      selectedBikeTitle.value = bike?.title || ""
    }

    async function deleteFolderImagesStorage(folderPath: string) {
      try {
        const storageFolderRef = storageRef(storage, folderPath)
        const storageListResult = await listAll(storageFolderRef)
        const deletePromises = storageListResult.items.map(
          (itemRef: StorageReference) => {
            return deleteObject(itemRef)
          }
        )

        await Promise.all(deletePromises)
      } catch (error) {
        console.error("Error deleting folder contents:", error)
      }
    }

    async function confirmDeleteBike() {
      isLoading.value = true
      try {
        await dbService.deleteItem(selectedBikeId.value)
        const index = bikes.value.findIndex(
          (bike) => bike._id === selectedBikeId.value
        )
        if (index !== -1) {
          bikes.value.splice(index, 1)
          filteredBikes.value.splice(index, 1)
          totalPages.value = Math.ceil(
            filteredBikes.value.length / itemsPerPage.value
          )
        }

        await deleteFolderImagesStorage(`bikes/${selectedBikeId.value}`)

        message.value = "Bike has been deleted"
        showMessage.value = true
        setTimeout(() => {
          showMessage.value = false
          isLoading.value = false
        }, 3000)
      } catch (error) {
        console.error("Error deleting bike: ", error)
      }
      showDeleteConfirmation.value = false
    }

    function deleteBike(id: string) {
      toggleDeleteConfirmation(id)
    }

    function editBike(id: string) {
      router.push(`/edit/${id}`)
    }

    function isUserLoggedIn() {
      if (!isLoggedIn.value) {
        router.push("/")
      }
    }

    onMounted(isUserLoggedIn)
    onMounted(fetchData)

    return {
      bikes: paginatedBikes,
      searchText,
      isLoading,
      colorLoading,
      currentPage,
      totalPages,
      nextPage,
      previousPage,
      filterBikes,
      editBike,
      deleteBike,
      showDeleteConfirmation,
      confirmDeleteBike,
      selectedBikeTitle,
      showMessage,
      message,
    }
  },
})
