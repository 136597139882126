
import { defineComponent } from "vue"
import ClipLoader from "vue-spinner/src/ClipLoader.vue"

export default defineComponent({
  name: "HandleBikesComponent",
  components: { ClipLoader },
  props: {
    bikes: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["load-more"],
  setup(_, { emit }) {
    const colorLoading = "#DC2626"
    let loadedBikesQuantity = 8
    const incrementLoaded = 4

    async function loadMoreBikes() {
      loadedBikesQuantity += incrementLoaded
      emit("load-more", loadedBikesQuantity)
    }

    return {
      colorLoading,
      loadMoreBikes,
      loadedBikesQuantity,
    }
  },
})
